@layer base {
    @font-face {
        font-family:"coresans";
        src:url('../fonts/coresansc35-webfont.eot');
        src:url('../fonts/coresansc35-webfont.eot?#iefix') format("embedded-opentype"),
        url('../fonts/coresansc35-webfont.woff2') format("woff2"),
        url('../fonts/coresansc35-webfont.ttf') format("truetype");
        font-weight:300;
        font-style:normal;
    }
   
    @font-face {
        font-family:"coresans";
        src:url('../fonts/coresansc55-webfont.eot');
        src:url('../fonts/coresansc55-webfont.eot?#iefix') format("embedded-opentype"),
        url('../fonts/coresansc55-webfont.woff2') format("woff2"),
        url('../fonts/coresansc55-webfont.ttf') format("truetype");
        font-weight:500;
        font-style:normal;
    }

    @font-face {
        font-family:"coresans";
        src:url('../fonts/coresansc65-webfont.eot');
        src:url('../fonts/coresansc65-webfont.eot?#iefix') format("embedded-opentype"),
        url('../fonts/coresansc65-webfont.woff2') format("woff2"),
        url('../fonts/coresansc65-webfont.ttf') format("truetype");
        font-weight:700;
        font-style:normal;
    }

    @font-face {
        font-family:"coresans";
        src:url('../fonts/coresansc95-webfont.eot');
        src:url('../fonts/coresansc95-webfont.eot?#iefix') format("embedded-opentype"),
        url('../fonts/coresansc95-webfont.woff2') format("woff2"),
        url('../fonts/coresansc95-webfont.ttf') format("truetype");
        font-weight:900;
        font-style:normal;
    }
}